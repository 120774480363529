import { Address, useCustomZip, useUserZip } from '@/hooks/useUserZip';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

interface UserZipType {
  userZip: Address | undefined;
  loading: boolean;
  error: boolean;
}

interface CustomZipType {
  customZip: string | undefined;
  customAddress: string | undefined;
  setCustomZip: (postal: string, address: string | undefined) => void;
  clearCustomZip: () => void;
}

interface ZipContextValue extends UserZipType, CustomZipType {
  preferredZip: string | undefined;
}

const ZipContext = createContext<ZipContextValue | null>(null);

export const ZipProvider: FC<PropsWithChildren> = ({ children }) => {
  const { zip: userZip, loading, error } = useUserZip();
  const customZipObj = useCustomZip();
  const {
    setCustomZip,
    clearCustomZip,
    zip: customZip,
    address: customAddress,
  } = customZipObj;

  let preferredZip: string | undefined;
  if (customZip) {
    preferredZip = customZip;
  } else if (userZip && userZip.country === 'US') {
    preferredZip = userZip.postal;
  }

  const contextValue = {
    userZip,
    loading,
    error,
    customZip,
    customAddress,
    setCustomZip,
    clearCustomZip,
    preferredZip,
  };

  return (
    <ZipContext.Provider value={contextValue}>{children}</ZipContext.Provider>
  );
};

export const useZipContext = () => {
  const context = useContext(ZipContext);
  if (!context) {
    throw new Error('useZipContext must be used within a ZipProvider');
  }
  return context;
};
