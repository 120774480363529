import { AnalyticsProvider } from '@/components/molecules/AnalyticsProvider';
import { AuthProvider } from '@/components/molecules/AuthProvider';
import { GoogleTagManager } from '@/components/molecules/GoogleTagMananger';
import { FooterTemplate } from '@/components/template/FooterTemplate';
import HeaderTemplate from '@/components/template/HeaderTemplate';
import { DealerContactProvider } from '@/context/DealerContact';
import { SavedVehiclesProvider } from '@/context/SavedVehicles';
import { ZipProvider } from '@/context/ZipContext';
import { CachedDataProvider } from '@/context/CachedData';
import '@/styles/globals.css';
import { BasePageProps } from '@/types';
import { Page } from '@/types/page';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Amplify } from 'aws-amplify';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { Lexend_Deca } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GOOGLE_API_KEY } from '@/lib/constants';
import { APIProvider as GoogleMapsAPIProvider } from '@vis.gl/react-google-maps';

const lexendDeca = Lexend_Deca({ subsets: ['latin'] });

// configure Amplify for authentication
const authConfig = {
  region: 'us-east-1',
  userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
  ssr: process.env.ENABLE_DEV === 'true' ? true : undefined,
  oauth: {
    domain: process.env.NEXT_PUBLIC_OAUTH_DOMAIN,
    redirectSignIn: process.env.NEXT_PUBLIC_REDIRECTION,
    redirectSignOut: process.env.NEXT_PUBLIC_REDIRECTION,
    responseType: 'code',
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
  },
};

const appSyncConfig = {
  aws_appsync_graphqlEndpoint: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.NEXT_PUBLIC_APPSYNC_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

Amplify.configure({
  Auth: authConfig,
  ...appSyncConfig,
  ssr: true,
});

const queryClient = new QueryClient();

interface CustomAppProps<T> extends Omit<AppProps, 'pageProps'> {
  Component: Page<BasePageProps>;
  pageProps: BasePageProps<T>;
}

function App<T>({
  Component,
  pageProps: { noHeader = false, noFooter = false, user, ...restPageProps },
}: CustomAppProps<T>) {
  const toastBackgroundClass = useMemo(
    () => ({
      success: 'bg-paleGreen/90 border-green-dark',
      error: 'bg-paleRed/90 border-red-medium box',
      info: 'bg-brandMidnight/90 border-neutral-200',
    }),
    []
  );

  const router = useRouter();
  const domain =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : `https://${process.env.NEXT_PUBLIC_DOMAIN}`;

  const enableSearchEngineIndexing =
    process.env.NEXT_PUBLIC_ENABLE_SEARCH_ENGINE_INDEXING === 'true';

  const lowercaseDomain = domain ? domain.toLowerCase() : '';

  const contentImage = lowercaseDomain?.includes('ev.com')
    ? `${domain}/images/og/ev_com_main.png`
    : `${domain}/images/og/ev_ca_main.png`;

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
  }, []);

  useEffect(() => {
    try {
      const ALLOWED_HOST_NAMES: ReadonlyArray<string> = [
        'ev.com',
        'ev.ca',
        'dev.ev-internal.com',
        'ev-internal.com',
      ];
      if (ALLOWED_HOST_NAMES.includes(window.location.hostname)) {
        const guestRoleArn = process.env.NEXT_PUBLIC_RUM_GUEST_ROLE_ARN;
        const identityPoolId = process.env.NEXT_PUBLIC_RUM_IDENTITY_POOL_ID;
        const applicationId = process.env.NEXT_PUBLIC_RUM_APPLICATION_ID;
        if (guestRoleArn && identityPoolId && applicationId) {
          const config: AwsRumConfig = {
            sessionSampleRate: 1,
            guestRoleArn,
            identityPoolId,
            endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
            telemetries: ['performance', 'errors', 'http'],
            allowCookies: true,
            enableXRay: false,
          };

          const APPLICATION_VERSION = '1.0.0';
          const APPLICATION_REGION = 'us-east-1';

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.rum = new AwsRum(
            applicationId,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
          );
        }
      }
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }, []);

  return (
    <>
      <Head>
        <link rel="canonical" href={`${domain}${router.asPath}`} />
        <link rel="icon" href="/favicon.ico" />
        {!enableSearchEngineIndexing && (
          <meta name="robots" content="noindex" />
        )}

        <meta property="og:image" content={contentImage} key="og-image" />

        <meta
          property="og:title"
          content="Electric Cars for Sale"
          key="og-title"
        />
        <meta property="og:type" content="website" key="og-type" />
        <meta
          property="og:description"
          content="Buy New & Pre-Owned Electric Vehicles."
          key="og-description"
        />
        <meta
          name="twitter:title"
          content="Electric Cars for Sale"
          key="twitter-title"
        />

        <meta
          property="og:url"
          content={`https://${process.env.NEXT_PUBLIC_DOMAIN}${router.asPath}`}
          key="og-url"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <main className={`${lexendDeca.className}`}>
        {Component.isIframe ? (
          <QueryClientProvider client={queryClient}>
            <Component {...restPageProps} />
          </QueryClientProvider>
        ) : (
          <>
            <AuthProvider user={user}>
              <AnalyticsProvider>
                <CachedDataProvider>
                  <QueryClientProvider client={queryClient}>
                    <SavedVehiclesProvider>
                      <DealerContactProvider>
                        <ZipProvider>
                          <GoogleMapsAPIProvider
                            apiKey={GOOGLE_API_KEY}
                            libraries={['places']}
                          >
                            <div className="flex min-h-screen flex-col">
                              <section className="flex flex-grow flex-col">
                                {!noHeader && <HeaderTemplate />}
                                <Component {...restPageProps} />
                                {!noFooter && <FooterTemplate />}
                              </section>
                            </div>
                          </GoogleMapsAPIProvider>
                        </ZipProvider>
                      </DealerContactProvider>
                    </SavedVehiclesProvider>
                  </QueryClientProvider>
                </CachedDataProvider>
              </AnalyticsProvider>
            </AuthProvider>
            <GoogleTagManager
              gaId={process.env.NEXT_PUBLIC_GTM_ID || ''}
              gAdsId={process.env.NEXT_PUBLIC_GADS_ID || ''}
            />
            <ToastContainer
              toastClassName={(context) => {
                const toastBgClassName =
                  context &&
                  context?.type &&
                  (context.type === 'info' ||
                    context.type === 'success' ||
                    context.type === 'error')
                    ? toastBackgroundClass[context.type]
                    : toastBackgroundClass.info;
                return `${toastBgClassName} relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer border-[1px] border-solid boxShadow-m`;
              }}
              bodyClassName={(context) => {
                const fontColor =
                  context?.type === 'info' ? 'text-white' : 'text-black';
                return `text-body2Regular px-l py-m ${fontColor}`;
              }}
              closeButton={false}
              position="bottom-center"
              toastStyle={{ alignSelf: 'center', maxWidth: 340 }}
              className="flex flex-col"
              icon={false}
              autoClose={3000}
              hideProgressBar
            />
          </>
        )}
      </main>
    </>
  );
}

export default appWithTranslation(App);
